import './MainPage.css';
import NcChatbot from '../../components/ncChatbot/NcChatbot';

function MainPage() {
  
  
  return (
    <section style={{height: "100%"}}>
      <NcChatbot />
    </section>
  );
}

export default MainPage;
