import React from 'react';
import { IMessageOptions } from 'react-chatbot-kit/build/src/interfaces/IMessages';
import { processMessage } from '../../data/api';
import { createClientMessage } from 'react-chatbot-kit';
import { useTranslation } from 'react-i18next';

const ActionProvider = ({
    createChatBotMessage,
    setState,
    children
}: {
    createChatBotMessage: (message: string, options: IMessageOptions) => {
        loading: boolean,
        widget?: string,
        delay?: number,
        payload?: any,
        message: string,
        type: string,
        id: number
    };
    setState: any;
    children: any
}) => {
    const { t } = useTranslation();
    const extractGPSCoordinates = (inputString: string): { lat: string, lon: string } | null => {
        const lonPattern = /(-3\.\d+)/;
        const latPattern = /(37\.\d+)/;
        const lonMatch = inputString.match(lonPattern);
        const latMatch = inputString.match(latPattern);
        if (lonMatch && latMatch) {
            return { lon: lonMatch[0], lat: latMatch[0] };
        }
        return null;
    }
    const handleMsg = (msg: string) => {
        const waiting = createChatBotMessage(t("Procesando..."), { delay: 0, loading: false });
        setState((prev: any) => ({
            ...prev,
            messages: [...prev.messages, waiting],
        }));
        processMessage(msg).then(resp => {
            console.log("resp", resp);
            let gpsCoordinates;
            try {
                gpsCoordinates = extractGPSCoordinates(resp.data.answer);
            } catch (e) {
                console.log("Error extracting GPS coordinates", e);
                gpsCoordinates = null;
            }

            const message = createChatBotMessage(resp.data.answer, { widget: gpsCoordinates != null ? 'ncwMap' : '', payload: { coords: gpsCoordinates } });
            setState((prev: any) => ({
                ...prev,
                messages: [...prev.messages.slice(0, -1), message],
            }));

        }).catch((err) => {
            console.log("error", err);
            let errMsg = t("unknown_error");
            if (err.response) {
                if (err.response.status == 429) {
                    errMsg = t("Eh! no soy tán rápido, espera un poco!")
                } else if (err.response.status == 500) {
                    errMsg = t("server_error")
                }
            } else if (err.request) {
                if (err.request.status == 429) {
                    errMsg = t("Eh! no soy tán rápido, espera un poco!")
                } else if (err.request.status == 500) {
                    errMsg = t("server_error")
                } else {
                    errMsg = t("connection_error")
                }
            } 
            const message = createChatBotMessage(errMsg, {});
            setState((prev: any) => ({
                ...prev,
                messages: [...prev.messages.slice(0, -1), message],
            }));
        });
    };

    const handleHintPosition = () => {
        const userMsg = t('¿Por dónde van las procesiones?');

        const message = createClientMessage(userMsg, {});
        setState((prev: any) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));
        handleMsg(userMsg);
    };
    const handleHintWeather = () => {
        const userMsg = t('¿Qué tiempo va a hacer?');

        const message = createClientMessage(userMsg, {});
        setState((prev: any) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));
        handleMsg(userMsg);
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleMsg,
                        handleHintPosition,
                        handleHintWeather
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;