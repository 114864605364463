import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import Logo from '../../components/ncChatbot/ncAvatar/logo.svg';
import { useTranslation } from "react-i18next";
import LogoMV from '../../assets/logos/mv-logo.svg';
import LogoLCA from '../../assets/logos/logo-lca-blanco.png';
import LogoAyto from '../../assets/logos/escudo-ayto-blanco.png';
import LogoFederacion from '../../assets/logos/escudo-federacion-cofradias-blanco.png';
import LogoWiber from '../../assets/logos/logo_wiber.svg';
import LogoAlsa from '../../assets/logos/logo-alsa.svg';


const AboutPage = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Container maxWidth="lg" sx={{
            bgcolor: theme.palette.primary.light,
            flexGrow: 1,
            marginTop: 0,
            paddingTop: "25px"

        }}>
            <Box display="flex" justifyContent="center" my={4} sx={{ marginTop: 0 }}>
                <img src={Logo} alt="Logo" style={{ width: '150px' }} />
            </Box>
            <Typography variant="h4" component="h3" gutterBottom>
                <span className="nazareno">nazareno</span><span className="chat">.chat</span>
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1" paragraph>
                        {t('aboutPage.intro1')}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        {t('aboutPage.intro2')}
                    </Typography>
                </Grid>
                {/*
                <Grid item xs={12} md={6}>
                    <Box>
                    </Box>
                </Grid>
                */}
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        {t('aboutPage.project_title')}
                    </Typography>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <a href="https://montevive.ai" target="_new">
                                <img src={LogoMV} alt="Logotipo de Montevive.ai" style={{ width: '80px' }} />

                                <p style={{ fontFamily: 'Montserrat', textTransform: 'uppercase', fontWeight: 'bold', color: "white" }}>Montevive.ai</p>
                            </a>
                            <p style={{ fontFamily: 'Montserrat' }}>AI Solutions</p>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <img src={LogoLCA} alt="Logotipo de La Ciudad Accesible" style={{ width: '240px' }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                    {t('aboutPage.collaborators_title')}
                    </Typography>
                    <Grid container spacing={4} justifyContent="center" style={{marginBottom: "0rem"}}>
                        <Grid item>
                            <img src={LogoFederacion} alt="Escudo de la Federación de Cofradias y Hermandades de la Semana Santa de Granada" style={{ width: '150px' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} justifyContent="center" style={{marginBottom: "1.2rem"}}>
                        <Grid item>
                            <img src={LogoAyto} alt="Escudo del Excenlentísimo Ayuntamiento de Granada" style={{ width: '150px' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} justifyContent="center" style={{marginBottom: "1.2rem"}}>
                        <Grid item>
                            <img src={LogoWiber} alt="Logo de Wiber" style={{ width: '150px' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center" style={{marginBottom: "1.2rem"}}>
                        <Grid item>
                            <img src={LogoAlsa} alt="Logo de ALSA" style={{ width: '150px' }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container >
    )
}

export default AboutPage;