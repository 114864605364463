import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend"

// Define your translation keys and their types
/*
declare module 'react-i18next' {
    interface CustomTypeOptions {
        defaultNS: 'translation';
        resources: {
            translation: {
                welcome: string;
                description: string;
            };
        };
    }
}

*/

i18n
    //.use(Backend)
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        
        detection: {
            // order: [ 'navigator', 'queryString'],
            // caches: ['cookie'],
            lookupQuerystring: 'lng'
        },
        
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        }
    });

export default i18n;