import React from 'react';
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import { Chatbot } from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css'
import './NcChatbot.css';
import { useTranslation } from 'react-i18next';
import { getUp } from '../../data/api';


const NcChatbot = () => {
  const { t } = useTranslation();
  const [latestNews, setLatestNews] = React.useState(null);
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await getUp();
        setLatestNews(response.data.alert);
      } catch (error) {
        console.error(error, "error getting up status");
        setLatestNews(null)
      } finally {
        setReady(true)
      }
    }
    // TODO: disabled while chatbot is out of Holy Week 
    // fetchData();
    setReady(true);

  }, []);
  return (
    <div className='chatbot'>
      {ready ?
        <Chatbot
          config={config(t, latestNews)}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          placeholderText={t('pregunta, pregunta')}
        />
        : null}
    </div>
  );
};
export default NcChatbot;
