import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import './NcAppBar.css';
import { Help } from '@mui/icons-material';

const NcAppBar = ({ onClick, onHelpClick }: { onClick: any, onHelpClick: any }) => {
  const handleClick = () => {
    onClick();
  };
  const handleHelpClick = () => {
    onHelpClick();
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <span className='nazareno'>nazareno</span><span className='chat'>.chat</span>
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleHelpClick}
            color="inherit"
          >
            <Help />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  )
};

export default NcAppBar;