import { ReactComponent as NazarenoLogo } from './logo.svg';
import './NcAvatar.css';

const NcAvatar = () => {
    return (
        <div className='react-chatbot-kit-chat-bot-avatar'>
            <div className='react-chatbot-kit-chat-bot-avatar-container'>
                <NazarenoLogo className='react-chatbot-kit-chat-bot-avatar-icon'/>
            </div>
        </div>
    )
}

export default NcAvatar;
