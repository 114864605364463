import React from 'react';
import { useTranslation } from 'react-i18next';

const NcwHint: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const style = {
    height: '45px',
    background: '#f0f0f0',
    border: 'solid 1px #aaa',
    padding: '10px',
    borderRadius: '15px',
    fontWeight: 'bold',
    margin: '10px'
  }
  return (
    <div>
      <button key='hint-position' style={style} onClick={props.actionProvider.handleHintPosition}>
        📍 {t('¿Por dónde van las procesiones?')}
      </button>
      <button key='hint-weather' style={style} onClick={props.actionProvider.handleHintWeather}>
      ⛅ {t('¿Qué tiempo va a hacer?')}
      </button>
   
    </div>
  )
};

export default NcwHint;