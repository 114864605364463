import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';

interface TermsModalProps {
    isOpen: boolean;
    onAccept: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ isOpen, onAccept }) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <Dialog
            open={isOpen}
            onClose={() => { }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t('termsAndConditions')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('nazarenoDescription')}
                </DialogContentText>
                <DialogContentText>
                    1. <strong>{t('inTestPhase')}</strong>
                </DialogContentText>
                <DialogContentText>
                    2. <strong>{t('noPersonalInfo')}</strong>
                </DialogContentText>
                <DialogContentText>
                    3. <strong>{t('continuousUpdate')}</strong>
                </DialogContentText>
                <DialogContentText>
                    4. {t('weStoreData')}
                </DialogContentText>
                <FormControlLabel
                    control={<Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />}
                    label={t('agree')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onAccept} disabled={!isChecked}>
                    {t('continue')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TermsModal;
