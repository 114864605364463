import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import GuideThumbnail from '../../../assets/guia-accesible-thumbnail.png';
import { useTranslation } from "react-i18next";

const NcwGuide: React.FC<any> = (props) => {
    const { t } = useTranslation();
    const style = {
        background: "#784F83"
    }
    
    const handleDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        // Directly navigating to the PDF URL will prompt the browser to either display or download the PDF, depending on its settings
        window.open("https://s.nazareno.chat/s/jTu9R2", '_blank');
      };
    return (
        <div className="react-chatbot-kit-chat-bot-message-container">
            <div className="react-chatbot-kit-chat-bot-message" style={style}>
                <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea onClick={handleDownload}>
                        <CardMedia
                            component="img"
                            height="140"
                            image={GuideThumbnail}
                            alt={t('alt_guide')}
                        />
                        <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                                {t('guideTitle')}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {t('guideInfo')}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </div>
    )
}
export default NcwGuide
