import axios, { AxiosResponse } from 'axios';
import { ClientJS } from 'clientjs';

const baseURL: string = process.env.REACT_APP_API_URL || '';
const apiClient = axios.create({
    baseURL: baseURL,
    responseType: 'json'
});

const clientJS = new ClientJS();
const user_token = clientJS.getFingerprint();

export function processMessage(msg: string): Promise<AxiosResponse<any>> {
    var data = {
        'user_token': `${user_token}`,
        'question': msg
    };
    return apiClient.post("/process", data);
}

export function getUp(): Promise<AxiosResponse<any>> {
    return apiClient.get("/up");
}
