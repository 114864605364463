import React from "react";

const NccAlert: React.FC<any> = (props) => {
    const style = {
        background: "#a10000"
    }
    const styleHeading = {
        'fontSize': '1.0rem',
    }
    const ncwAlertMessages = props.state.messages.filter((message: any) => message.type === "nccAlert");
    const lastMessage = ncwAlertMessages[ncwAlertMessages.length - 1];
    
    return (
        <div className="react-chatbot-kit-chat-bot-message-container">
            <div className="react-chatbot-kit-chat-bot-message" style={style}>
                <h2 style={styleHeading}>📣 ÚLTIMA HORA 📣</h2>
                <p>{lastMessage.message}</p>
            </div>
        </div>
    )
}
export default NccAlert;
