import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const NcwMap: React.FC<any> = (props) => {
    const center = {lat: parseFloat(props.payload.coords.lat), lng: parseFloat(props.payload.coords.lon)};
    const zoom = 18;
  return (
    <MapContainer center={center} zoom={zoom} style={{ height: '400px', width: '100%' }}>
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    />
    <Marker position={center}>
      {/* 
      <Popup>
        A pretty CSS3 popup. <br /> Easily customizable.
      </Popup>
    */}
    </Marker>
  </MapContainer>
  )
};

export default NcwMap;