import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';
import MainPage from './pages/MainPage';
import AboutPage from './pages/AboutPage';
import { getUp } from './data/api';
// import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from './firebase';
import { ToastContainer, toast } from 'react-toastify';
import Message from './Message';
import NcAppDown from './components/NcAppDown';
import NcAppLaunch from './components/NcAppLaunch';
import NcAppBar from './components/ncAppBar/NcAppBar';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import NcHelpModal from './components/NcHelp';
import { Chat, HelpOutlineOutlined, InfoOutlined } from '@mui/icons-material';
import "react-toastify/dist/ReactToastify.css";
import TermsModal from './components/NcAgreement';
import './i18n';
import { useTranslation } from 'react-i18next';

const App = () => {
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
    const [isTermsAccepted, setIsTermsAccepted] = React.useState<boolean>(false);
    const [appStatus, setAppStatus] = React.useState<string>('');
    const [open, setOpen] = React.useState(false);
    const [helpOpen, setHelpOpen] = React.useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    React.useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };
    const showHelp = () => {
        setHelpOpen(true);
    };
    const handleHelp = () => {
        setHelpOpen(false);
    }
    const goToAbout = () => {
        navigate('/about');
    }
    const goToChat = () => {
        navigate('/');
    }
    /* TODO: disable while chatbot is not in Holy week */
    /*
    React.useEffect(() => {
        getUp().then((res) => {
            console.log(res.data.mode);
            setAppStatus(res.data.mode)
        }).catch((err) => {
            console.log("error getting mode", err);
            setAppStatus("down");
        });
    }, []);
    */
    React.useEffect(() => {
        const termsAccepted = localStorage.getItem('termsAccepted') === 'true';
        setIsTermsAccepted(termsAccepted);
    }, []);

    const handleAcceptTerms = () => {
        setIsTermsAccepted(true);
        localStorage.setItem('termsAccepted', 'true');
    };
    /*
    async function requestPermission() {
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey: 'BDIxBztItRpL-VSkuI-vQUYdJhxbKRcT8VjdNrwkpvrMlvBsxt--OJwU2MHdF4RSVVG_kp-kewTnwG0WSffLoSA',
            });

            //We can send token to server
            console.log("Token generated : ", token);
        } else if (permission === "denied") {
            //notifications are blocked
            alert("You denied for the notification");
        }
    }
    
    React.useEffect(() => {
        requestPermission();
    }, []);
    */

    /*
    onMessage(messaging, (payload) => {
        toast(<Message notification={payload.notification} />);
    });
    */
    if (appStatus === "down") {
        return <NcAppDown></NcAppDown>;
    } else if (appStatus === "pre") {
        return <NcAppLaunch></NcAppLaunch>;
    }
    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                <ListItem key="chat" disablePadding>
                    <ListItemButton onClick={goToChat}>
                        <ListItemIcon>
                            <Chat />
                        </ListItemIcon>
                        <ListItemText primary="Chat" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="help" disablePadding>
                    <ListItemButton onClick={showHelp}>
                        <ListItemIcon>
                            <HelpOutlineOutlined />
                        </ListItemIcon>
                        <ListItemText primary={t('¿Cómo funciona?')} />
                    </ListItemButton>
                </ListItem>
                {/*
                <ListItem key="telegram" disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <Telegram />
                        </ListItemIcon>
                        <ListItemText primary="También estoy en Telegram" />
                    </ListItemButton>
                </ListItem>
                */}
                <ListItem key="about" disablePadding>
                    <ListItemButton onClick={goToAbout}>
                        <ListItemIcon>
                            <InfoOutlined />
                        </ListItemIcon>
                        <ListItemText primary={t('Sobre mi')} />
                    </ListItemButton>
                </ListItem>

            </List>
        </Box>
    );
    const showDrawer = () => {
        setOpen(true);
    }
    return (
        <div className='App' style={{ height: `${windowHeight}px`}}>
            <header>
                <TermsModal isOpen={!isTermsAccepted} onAccept={handleAcceptTerms} />
                <NcAppBar onClick={showDrawer} onHelpClick={showHelp} />
                <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
                    {DrawerList}
                    <List style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                        <ListItem>
                            <ListItemText primary={`v${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_MODE}`} />
                        </ListItem>
                    </List>
                </Drawer>
                <ToastContainer />
            </header>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/about" element={<AboutPage />} />
            </Routes>
            <section>
                <NcHelpModal open={helpOpen} setOpen={handleHelp} />
            </section>
        </div>
    );
}

export default App;
