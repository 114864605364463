import { Button, Dialog, useMediaQuery, useTheme, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useTranslation } from "react-i18next";

const styleList = {
    fontFamily: 'Helvetica',
    color: 'rgba(0,0,0, 0.6)',
    fontWeight: 'bold',
}
const NcHelpModal = ({ open, setOpen }: { open: any, setOpen: any }) => {
    const { t } = useTranslation();
    const handleClose = () => setOpen(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                fullScreen={fullScreen}
            >
                <DialogTitle id="modal-modal-title">{t('¿Te ayudo?')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p>{t('Soy')} <strong>{t('Nazareno.Chat')}</strong> {t('y lo sé todo sobre la')} <strong>{t('Semana Santa Granadina')}</strong> ({t('bueno, alguna cosa se me escapará')}).</p>
                        <p>{t('Estoy entrenado para responder todo tipo de cuestiones de la Semana Santa')}</p>
                        <p>{t('Puedes preguntarme cosas como')}:</p>
                    </DialogContentText>
                    <ul style={styleList}>
                        <li>{t('¿Por dónde va la procesión del Silencio? (o tu preferida, claro)')}</li>
                        <li>{t('¿Qué procesiones estan en la calle ahora?')}</li>
                        <li>{t('¿Qué actividades hay en hoy? ¿Son accesibles?')}</li>
                        <li>{t('¿Qué sabes sobre los pasos de "pon aqui tu Hermandad favorita"?')}</li>
                        <li>{t('...y ¡mil cosas más! pregunta, pregunta')}</li>
                    </ul>
                    <DialogContentText>
                        <p>{t('Se me olvidaba, soy')} <strong>{t('100% gratuito')}</strong>.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        {t('cerrar')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default NcHelpModal;