import React from 'react';

const MessageParser = ({ children, actions }: {
    children: any;
    actions: {
        handleMsg: (msg: string) => void
    };
}) => {
  const parse = (message: string) => {
    
    actions.handleMsg(message);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
